// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { FpjsProvider, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react';

const apiKeys = [
  "ZwOQoBq4v3pq6b4oVCKR",
  "llbqCn4Ax08nsPIUFzhs",
  "WqcUP2JBvyO26jKlQOoN",
  "M8OLNXulUBG4T4MckgCy",
  "lJATKAM2MnRKy6gliuxH",
  "InVj0JR47D6KdKmgaxBN",
  "mxhLBoPBUCOS9yC9ZCid",
  "7FyNkeJ40JJs6RgIDwe3"
];

const randomApiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: randomApiKey,
        endpoint: [
          "https://api.lillliiilllliiiiilliilllllllliiii.site",
          FingerprintJSPro.defaultEndpoint
        ],
        scriptUrlPattern: [
          "https://api.lillliiilllliiiiilliilllllllliiii.site/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJSPro.defaultScriptUrlPattern
        ]
      }}
    >
      <App />
    </FpjsProvider>
  </React.StrictMode>
);
