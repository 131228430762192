import React, { useEffect } from 'react';
import LoadingScreen from './component/LoadingScreen/LoadingScreen';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};

const App = () => {
  const { isLoading, error, data } = useVisitorData({ extendedResult: true }, { immediate: true });

  useEffect(() => {
    if (data?.requestId) {
      console.log('Request ID:', data.requestId);
      setCookie('requestId', data.requestId, 1);
      
      // Refresh the page immediately after setting the cookie
      window.location.href = '/';
    }
  }, [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <LoadingScreen />
    </div>
  );
};

export default App;
